import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import WelcomeComp from "./WelcomeComp"
import BarChart from "./BarChart"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import StackedColumnChart from "./StackedColumnChart"
import RegistrationByGender from "./RegistrationByGender"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import LatestTransaction from "./LatestTransactionState"

const StateSupervisorDashboard = props => {
  useEffect(() => {
    getDashboardStatsState
  }, [])

  const [dashboardStats, setDashboardStats] = useState(null)
  const [statesWithHighestAgents, setStatesWithHighestAgents] = useState([])

  const getDashboardStatsState = useQuery({
    queryKey: ["getDashboardStatsState"],
    queryFn: () => {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      return GENERAL.getDashboardStatsState()
    },
    onSuccess: async res => {
      Swal.close()
      setDashboardStats(res)
      setStatesWithHighestAgents(res.lgaStatistics)
    },
  })

  const genderPieChart = [
    dashboardStats?.applicationByGender.males,
    dashboardStats?.applicationByGender.female,
  ]
  const statisticsBarChart = [
    dashboardStats?.totalApprovedAgents,
    dashboardStats?.totalDeclinedAgents,
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
              <RegistrationByGender
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={genderPieChart}
              />
              <BarChart
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={statisticsBarChart}
              />
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium ">
                            Total Registration Agents
                          </p>
                          <h4 className="mb-0 text-primary">
                            {dashboardStats?.totalApplicants || 0}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-approved-agent-registrations-by-state"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Approved Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalApprovedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-pending-agent-registrations-by-state"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Pending Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalPendingAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/view-declined-agent-registrations-by-state"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Declined Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalDeclinedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      LGAs with the highest registration agents
                    </h4>
                  </div>
                  <StackedColumnChart
                    periodData={statesWithHighestAgents}
                    dataColors='["--bs-primary", "--bs-primary", "--bs-primary"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
            <LatestTransaction />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

StateSupervisorDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(StateSupervisorDashboard)
