import React from 'react';
import PropTypes from 'prop-types';

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 0.5,
            border: 'none',
        }}
    />
);

ColoredLine.propTypes = {
    color: PropTypes.string
};

ColoredLine.defaultProps = {
    color: 'black'
};

export default ColoredLine;
