import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const StackedColumnChart = ({ dataColors, periodData, regionalCategories }) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors)

  // Extract state names and registration agents count from periodData
  const categories = periodData.map(data => data.name);
  const seriesData = periodData.map(data => data.registrationAgentsCount);
  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: 0,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "25%",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: categories,
      labels: {
        show: true,
      },
    },
    colors: ['#0077b6', '#fb8500'],
    legend: {
      position: "bottom",
      show: false,
    },
    fill: {
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[{ name: "Approved Agents", data: seriesData }]}
        type="bar"
        height="500"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
