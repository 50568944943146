import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { useQuery } from "react-query"
import WelcomeComp from "./WelcomeComp"
import PieChart2 from "./PieChart2"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"

import Swal from "sweetalert2"
import { GENERAL } from "api/general"
import { withTranslation } from "react-i18next"
import LatestTranactionNimc from "./LatestTranactionNimc"
import ProgressChart from "../../components/Progress"

const NimcStaffDashboard = props => {
  const [dashboardStats, setDashboardStats] = useState(null)
  const [male, setMale] = useState(0)
  const [female, setFemale] = useState(0)
  const [approved, setApproved] = useState(0)
  const [rejected, setRejected] = useState(0)

  const getDashboardStatisticsNimc = useQuery({
    queryKey: ["getDashboardStatisticsNimc"],
    queryFn: () => {
      Swal.fire({
        title: "Loading...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      return GENERAL.getDashboardStatisticsNimc("goal")
    },
    onSuccess: async res => {
      Swal.close()
      setDashboardStats(res)
      setMale(res.applicationByGender.male)
      setFemale(res.applicationByGender.female)
      setApproved(res.applicationPercentages.approved)
      setRejected(res.applicationPercentages.rejected)
    },
  })
  const genderPieChart = [male, female]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total Registration Agents
                          </p>
                          <h4 className="mb-0 text-primary">
                            {dashboardStats?.totalApplicants || 0}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link to={"/approved-agents-registration"}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Approved Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalApprovedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link
                        to={
                          "/view-pending-agent-registrations-by-supervisor"
                        }
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Pending Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalPendingAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Link
                        to={
                          "/view-declined-agent-registrations-by-lga-nimc-staff"
                        }
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Declined Registration Agents
                            </p>
                            <h4 className="mb-0">
                              {dashboardStats?.totalDeclinedAgents || 0}
                            </h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx bx-copy-alt font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <PieChart2
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={genderPieChart}
              />
            </Col>
            <Col xl={6}>
              <Col xl="12">
                <Card style={{ height: 200 }}>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Approved and Rejected applications
                    </CardTitle>
                    <ProgressChart
                      value={approved}
                      color={"info"}
                      name={"Approved"}
                    />

                    <ProgressChart
                      value={rejected}
                      color={"warning"}
                      name={"Declined"}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestTranactionNimc />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

NimcStaffDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(NimcStaffDashboard)
