import React, { Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  Input,
  Form,
  Label,
} from "reactstrap"
import { useMutation } from "react-query"
import { GENERAL } from "../../api/general"
import { useNavigate } from "react-router-dom"
import { useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import PaginatedDataTable from "components/PaginatedDataTable"
import { useLocation } from "react-router-dom"

const ThresholdManagementByLGA = props => {
  const location = useLocation()
  const { id: stateId, name } = location.state || {}
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [filter, setFilter] = useState("")
  const [show, setShow] = useState(false)
  const [currentThreshold, setCurrentThreshold] = useState("")
  const [newThreshold, setNewThreshold] = useState("")
  const [lgaName, setLgaName] = useState("")
  const [lgaId, setLgaId] = useState("")

  const [value, setValue] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const showModal = (id, threshold) => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const updateThresholdMutation = useMutation({
    mutationFn: async (payload) => {
      Swal.fire({
        title: "Updating threshold...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      return GENERAL.updateThreshold(payload);
    },
    onError: (error) => {
      Swal.close()
      Swal.fire('Error updating threshold', error.message, 'error');
    },
    onSuccess: (data) => {
      Swal.close()
      closeModal()
      setNewThreshold('')
      if(data.status === 500) {
        Swal.fire('Success', "New threshold must be greater than the existing threshold", 'error');
      } else {
        Swal.fire('Success', 'Threshold updated successfully', 'success').then(() => {
          fetchRecords(page, pageSize, filter, stateId)
        })
      }
    },
  });

  const handleUpdateThreshold = () => {
    if (!lgaId || !newThreshold) {
      Swal.fire('Validation error', 'LGA ID and Threshold are required', 'error');
      return;
    }

    const payload = { lgaId: lgaId.toString(), threshold: newThreshold.toString() };
    console.log(payload)
    updateThresholdMutation.mutate(payload);
  };

  const fetchRecords = async (page, pageSize, filter) => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    try {
      const response = await getLGAsThreshold.mutateAsync({
        page,
        pageSize,
        searchKey: filter ?? "",
        stateId,
      })
      Swal.close()
      setData(response.palliative)
      setTotalRecords(response.totalRecords)
    } catch (error) {
      Swal.fire({
        title: "Error fetching records",
        text: error.message,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    fetchRecords(page, pageSize, filter, stateId)
  }, [page, pageSize])

  const getLGAsThreshold = useMutation({
    mutationFn: async ({ page, pageSize, searchKey, stateId }) => {
      return GENERAL.getLGAsThreshold({ page, pageSize, searchKey, stateId })
    },
    onError: error => {
      Swal.fire("Error fetching records", error.message, "error")
    },
  })

  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = (newPageSize, page) => {
    setPageSize(newPageSize)
    setPage(1)
  }

  const startIndex = (page - 1) * pageSize

  const handleSearchFn = async () => {
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    setPage(1)
    await fetchRecords(1, pageSize, filter.trim())
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const columns = [
    {
      name: "#",
      sortable: true,
      selector: (row, index) => startIndex + index + 1,
      width: "4rem",
    },
    {
      name: "LGA",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Threshold",
      sortable: true,
      selector: row => row.threshold,
    },
    {
      name: "Total Applications",
      sortable: true,
      selector: row => row.totalApplications,
    },
    {
      name: "Successful Applications",
      sortable: true,
      selector: row => row.successfulApplications,
    },
    {
      name: "Unsuccessful Applications",
      sortable: true,
      selector: row => row.unSuccessfulApplications,
    },
    {
      name: "",
      disableFilters: true,
      cell: row => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => updateThreshold(row)}
        >
          Update Threshold
        </Button>
      ),
    },
  ]

  const updateThreshold = row => {
    const id = row?.id
    const threshold = row?.threshold
    if (!id) {
      return Swal.fire("Process failed", "LGA not found", "error")
    }
    setCurrentThreshold(row.threshold)
    setLgaName(row.name)
    setLgaId(id)
    showModal(id, threshold)
  }

  return (
    <React.Fragment>
      <Modal size="m" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <Col lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5 className="text-center">Threshold Update</h5>
                <div onClick={closeModal}>
                  <i
                    className="bx bx-x"
                    style={{ fontSize: 24, cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            </Col>
            <p className="">
              Note that all changes to the threshold must be saved.
            </p>

            <Form>
              <Col>
                <Col md={12}>
                  <div className="mt-3 mb-3">
                    <h5 className="mb-4">{lgaName}</h5>
                    <Label htmlFor="formrow-email-Input">
                      Current Threshold
                    </Label>
                    <div className="col-md-12">
                      <Input
                        type="text"
                        name="currentThreshold"
                        value={currentThreshold}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-email-Input">New Threshold</Label>
                    <div className="col-md-12">
                      <Input
                        type="number"
                        name="newThreshold"
                        value={newThreshold}
                        placeholder="Enter new threshold"
                        onChange={evt => setNewThreshold(evt.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Col>
            </Form>
          </Row>

          <Row>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary w-80 ml-5"
                onClick={handleUpdateThreshold}
              >
                Save Update
              </button>
            </div>
          </Row>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Threshold Management")}
              breadcrumbItem={props.t("Threshold Management")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">All LGAs in {name} State</div>
              <Fragment>
                <Row className="mb-2">
                  <React.Fragment>
                    <Col md={6}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => setFilter(e.target.value)}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`Search table`}
                              value={filter || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </React.Fragment>
                </Row>

                <PaginatedDataTable
                  columns={columns}
                  data={data}
                  totalRecords={totalRecords}
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                />
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ThresholdManagementByLGA)
