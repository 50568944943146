import React, { Fragment, useState, useEffect } from "react"
import { Container, Col, Row, Card, CardBody, Button } from "reactstrap"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { GENERAL } from "../../api/general"
import { useNavigate } from "react-router-dom"
import { useAsyncDebounce } from "react-table"
import { data } from "common/data/states"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import ColoredLine from "components/ColouredLine"

const ProcessPayment = props => {
  const navigate = useNavigate()
  const [isErr, setIsErr] = useState(false)
  const [records, setRecords] = useState([])
  const [value, setValue] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  useEffect(() => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    getApprovedAgents
  }, [])

  const getApprovedAgents = useQuery({
    queryKey: ["getDataPlans"],
    queryFn: () => {
      return GENERAL.getApprovedAgents()
    },
    onSuccess: async res => {
      Swal.close()
      if (res?.palliative) {
        setIsErr(false)
        setRecords(
          res?.palliative?.sort((a, b) => a.name.localeCompare(b.name))
        )
      }
      if (res.status === 500) {
        setIsErr(true)
      }
    },
  })

  function getItemByName(arr, name) {
    return arr.find(item => String(item.name).toLowerCase() === name)?.logo
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Process Payment")}
            breadcrumbItem={props.t("Process Payment")}
            hasBackBtn={true}
          />

          <Row>
            {records?.map((item, key) => (
              <Col key={"_col_" + key} md="3">
                <Card className="mini-stats-wid">
                  <CardBody
                    style={{
                      paddingTop: 40,
                      paddingBottom: 40,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <div className="text-center mb-4">
                      <img
                        src={getItemByName(
                          data,
                          String(item.name).toLowerCase()
                        )}
                        width={100}
                        height={100}
                        style={{ textAlign: "center" }}
                      />

                      <div className="row justify-content-center mt-3">
                        <div className="col-xl-10">
                          <h4 className="text-primary">{item.name}</h4>
                        </div>
                      </div>
                    </div>

                    {/* <Col className="d-flex align-items-center justify-content-center">
                      <Button
                        type="button"
                        color="primary"
                        style={{ fontSize: "11px", padding: "8px 90px" }}
                        className="ml-auto"
                      >
                        {0}
                      </Button>
                    </Col> */}

                    <div className="mt-3">
                      <ColoredLine color="grey" />
                    </div>

                    <Col className="mt-3">
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "0.9rem",
                          color: "#000",
                        }}
                      >
                        <Link
                          to={`/payment-list/${item?.id}?state=${item.name}`}
                        >
                          View List
                        </Link>
                      </p>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ProcessPayment)
