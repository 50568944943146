import React, { Fragment, useState, useEffect } from "react"
import { Container, Col, Row, Button, Card, CardBody } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMutation } from "react-query"
import { useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GENERAL } from "api/general"
import Swal from "sweetalert2"
//i18n
import { withTranslation } from "react-i18next"
import PaginatedDataTable from "components/PaginatedDataTable"

const ViewTrainingAttendanceByLga = props => {
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [filter, setFilter] = useState("")

  const [value, setValue] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const fetchRecords = async (page, pageSize, filter) => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    try {
      const response = await getRecordsByLgaId.mutateAsync({
        page,
        pageSize,
        searchKey: filter ?? "",
      })
      Swal.close()
      setData(response.palliative)
      setTotalRecords(response.totalRecords)
    } catch (error) {
      Swal.fire({
        title: "Error fetching records",
        text: error.message,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    fetchRecords(page, pageSize, filter)
  }, [page, pageSize])

  const getRecordsByLgaId = useMutation({
    mutationFn: async ({ page, pageSize, searchKey }) => {
      return GENERAL.getRecordsByLgaId({ page, pageSize, searchKey })
    },
    onError: error => {
      Swal.fire("Error fetching records", error.message, "error")
    },
  })

  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = (newPageSize, page) => {
    setPageSize(newPageSize)
    setPage(1)
  }

  const startIndex = (page - 1) * pageSize

  const handleSearchFn = async () => {
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    setPage(1) // Reset to first page when searching
    await fetchRecords(1, pageSize, filter.trim())
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const columns = [
    {
      name: "#",
      sortable: true,
      selector: (row, index) => startIndex + index + 1,
      width: "4rem",
    },
    {
      name: "Application ID",
      selector: row => row.npc_id,
      sortable: true,
    },
    {
      name: "First Name",
      sortable: true,
      selector: row => row.first_name,
    },
    {
      name: "Last Name",
      sortable: true,
      selector: row => row.last_name,
    },
    {
      name: "Phone No.",
      sortable: true,
      selector: row => row.phone,
    },
    {
      name: "State of Residence",
      sortable: true,
      selector: row => row.state_resid,
    },
    {
      name: "LGA of Residence",
      sortable: true,
      selector: row => row.lga_resid,
    },
    {
      name: "Date Created",
      sortable: true,
      selector: row => new Date(row.created_at).toLocaleDateString(),
    },
    {
      name: "Attendance",
      disableFilters: true,
      cell: row =>
        row.has_attendance ? (
          <div
            style={{
              color: row.attendance_status === "PASSED" ? "green" : "red",
            }}
          >
            {row.attendance_status}
          </div>
        ) : (
          <>
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              style={{ cursor: "pointer" }}
              onClick={() => {
                markAttendance(row)
              }}
            >
              Pass
            </Button>
            <Button
              type="button"
              color="danger"
              className="btn-sm btn-rounded"
              style={{ marginLeft: 10 }}
              onClick={() => {
                markAttendanceAbscent(row)
              }}
            >
              Fail
            </Button>
          </>
        ),
    },
  ]

  const markAttendance = row => {
    Swal.fire({
      title: "Marking attendance...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const npc_id = row?.npc_id
    const markAttendanceDto = {
      npc_id: npc_id,
      status: "PRESENT",
    }
    markAttendanceFn.mutate(markAttendanceDto)
  }

  const markAttendanceFn = useMutation({
    mutationFn: payload => {
      return GENERAL.markAttendance(payload)
    },
    onSuccess: async res => {
      Swal.close()
      if (res?.data?.palliative?.status == 0) {
        Swal.fire("Process Failed!", res?.data?.palliative.msg, "error").then(
          () => {
            fetchRecords(page, pageSize, filter)
          }
        )
      }
      if (res?.data?.palliative?.status == 1) {
        Swal.fire(
          "Process Successful!",
          res?.data?.palliative.msg,
          "success"
        ).then(() => {
          fetchRecords(page, pageSize, filter)
        })
      }
    },
    onError: async res => {
      Swal.close()
      Swal.fire("Process Failed!", res?.data?.palliative.msg, "error").then(
        () => {
          fetchRecords(page, pageSize, filter)
        }
      )
    },
  })

  const markAttendanceAbscent = row => {
    Swal.fire({
      title: "Marking attendance...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    const npc_id = row?.npc_id
    const markAttendanceDto = {
      npc_id: npc_id,
      status: "ABSENT",
    }
    markAttendanceAbscentFn.mutate(markAttendanceDto)
  }

  const markAttendanceAbscentFn = useMutation({
    mutationFn: payload => {
      return GENERAL.markAttendance(payload)
    },
    onSuccess: async res => {
      Swal.close()
      if (res?.data?.palliative?.status == 0) {
        Swal.fire("Process Failed!", res?.data?.palliative.msg, "error").then(
          () => {
            fetchRecords(page, pageSize, filter)
          }
        )
      }
      if (res?.data?.palliative?.status == 1) {
        Swal.fire(
          "Process Successful!",
          res?.data?.palliative.msg,
          "success"
        ).then(() => {
          fetchRecords(page, pageSize, filter)
        })
      }
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Breadcrumbs
              title={props.t("Training Attendance")}
              breadcrumbItem={props.t("Training Attendance")}
              hasBackBtn={true}
            />
          </Row>

          <Row>
            <Col md="3">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          Total Registration Agents
                        </p>
                        <h4 className="mb-0">{totalRecords || 0}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={"bx bx-copy-alt font-size-24"}></i>
                        </span>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">Training Attendance</div>
              <Fragment>
                <Row className="mb-2">
                  <React.Fragment>
                    <Col md={6}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => setFilter(e.target.value)}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`Search table`}
                              value={filter || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </React.Fragment>
                </Row>

                <PaginatedDataTable
                  columns={columns}
                  data={data}
                  totalRecords={totalRecords}
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                />
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewTrainingAttendanceByLga)
