import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const PieChart2 = ({periodData}) => {
  const series = periodData;
  const options = {
    labels: ["Male", "Female"],
    colors: ['#0077b6', '#F1b44c'],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
        },
      },
    },
  };

  return (
    <React.Fragment>
  <Col xl="12">
    <Card style={{ height: 200 }}>
      <CardBody>
        <h4 className="card-title mb-4">Applicants Statistics by gender</h4>

        <div className="d-flex align-items-center">
          <div id="donut-chart" className="flex-grow-1">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height={160}
              className="apex-charts"
            />
          </div>

          <div className="text-muted">
            <div className="mt-4">
              <p className="mb-2 text-truncate">
                <i className="mdi mdi-circle me-1" style={{ color: '#0077b6' }} />Male
              </p>
            </div>
            <div className="mt-4">
              <p className="mb-2 text-truncate">
                <i className="mdi mdi-circle me-1" style={{ color: '#F1b44c' }} /> Female
              </p>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
</React.Fragment>

  );
};

export default PieChart2;
