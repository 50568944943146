import React, { Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import { useMutation } from "react-query"
import { GENERAL } from "../../api/general"
import { useNavigate } from "react-router-dom"
import { useAsyncDebounce } from "react-table"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import ProgressChart from "components/Progress"
import PieCharts from "components/PieCharts"
import StackedColumnChart from "pages/Dashboard/StackedColumnChart"

const Payments = props => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [filter, setFilter] = useState("")

  const [value, setValue] = React.useState("")
  const onChange = useAsyncDebounce(value => {
    setFiilter(value || undefined)
  }, 200)

  const fetchRecords = async (page, pageSize, filter) => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    try {
      const response = await getApprovedRecordsList.mutateAsync({
        page,
        pageSize,
        searchKey: filter ?? "",
      })
      Swal.close()
      setData(response.palliative)
      setTotalRecords(response.totalRecords)
    } catch (error) {
      Swal.fire({
        title: "Error fetching records",
        text: error.message,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    fetchRecords(page, pageSize, filter)
  }, [page, pageSize])

  const getApprovedRecordsList = useMutation({
    mutationFn: async ({ page, pageSize, searchKey }) => {
      return GENERAL.getApprovedRecordsList({ page, pageSize, searchKey })
    },
    onError: error => {
      Swal.fire("Error fetching records", error.message, "error")
    },
  })

  const handleSearchFn = async () => {
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    setPage(1) // Reset to first page when searching
    await fetchRecords(1, pageSize, filter)
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Payments")}
            breadcrumbItem={props.t("Payments")}
            hasBackBtn={true}
          />

          <Row>
            <Col xl="4">
              <PieCharts
                style={{ height: 420 }}
                dataColors='["--bs-primary", "--bs-success"]'
                periodData={["60", "50"]}
                title={"Paid and unpaid allowances in percentage"}
                labelOne={"Paid"}
                labelTwo={"Unpaid"}
                colorOne={"#0E9738"}
                colorTwo={"#06d6a0"}
              />
              <Col xl={12}>
                <Col xl="12">
                  <Card style={{ height: 420 }}>
                    <CardBody>
                      <CardTitle className="mb-4">
                        States with lowest paid allowances
                      </CardTitle>
                      <ProgressChart value={50} name={"Nasarrawa"} />

                      <ProgressChart value={40} name={"Adamawa"} />

                      <ProgressChart value={30} name={"Anambra"} />

                      <ProgressChart value={20} name={"Gombe"} />

                      <ProgressChart value={10} name={"Ekiti"} />
                    </CardBody>
                  </Card>
                </Col>
              </Col>
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Paid Allowances
                          </p>
                          <h4 className="mb-0 text-primary">
                            {/* ₦{dashboardStats?.totalApplicants || 0} */}
                            ₦3,500,000
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-copy-alt font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="8" className="d-flex align-items-center justify-content-end">
                  <Button
                    type="button"
                    color="primary"
                    style={{ fontSize: "12px", padding: "8px 20px" }}
                    className="ml-auto"
                    onClick={() => navigate(`/process-payment`)}
                  >
                    Process Payment
                  </Button>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      States with the highest paid allowances
                    </h4>
                  </div>
                  <StackedColumnChart
                    periodData={["60", "50", "70"]}
                    dataColors='["--bs-primary", "--bs-primary", "--bs-primary"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Payments)
