import React from "react"
import { Col, Card, CardBody, CardTitle } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const BarChart = ({ dataColors, periodData }) => {
   // Extract the values for approved and rejected
   const approvedData = [periodData[0]];
   const rejectedData = [periodData[1]];
  const series = [
    {
      name: "Applications",
      data: periodData,
    }
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: ['#0077b6', '#fb8500'], 
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: ["Approved", "Declined"],
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        if (seriesIndex === 0) {
          return '';
        }
        return undefined;
      },
    },
  };

  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Approved and declined applications statistics</CardTitle>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="150"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default BarChart;