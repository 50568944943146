import { utils, writeFile } from "xlsx"
import { jsPDF } from "jspdf"

export const convertToCSV = (data, columns) => {
  if (!data || !columns || data.length === 0) {
    return ""
  }

  const header = columns.map(column => `"${column.name}"`).join(",") + "\n"
  const csv = data
    .map(row =>
      columns
        .map(column => {
          const value =
            typeof column.selector === "function"
              ? column.selector(row)
              : row[column.selector]
          return `"${value}"`
        })
        .join(",")
    )
    .join("\n")
  return header + csv
}

export const convertToXLSX = (data, columns, filename) => {
  if (!data || !columns || data.length === 0) {
    return
  }

  // Initialize row index for numbering
  let rowIndex = 1

  // Prepare data for XLSX
  const xlsxData = data.map(row => {
    const rowData = columns.map(column => {
      if (column.name === "#") {
        // Handle row number column
        const rowNumber = rowIndex
        rowIndex++ // Increment row index for next row
        return rowNumber
      } else if (column.name === "Total Amount") {
        // Handle Total Amount column with Naira symbol
        return `₦${row.totalAmount}` // Ensure Naira symbol is correctly handled
      } else {
        // Handle other columns normally
        const value =
          typeof column.selector === "function"
            ? column.selector(row)
            : row[column.selector]
        return value !== undefined ? value : ""
      }
    })

    return rowData
  })

  // Create XLSX workbook and sheet
  const worksheet = utils.aoa_to_sheet([
    columns.map(column => column.name),
    ...xlsxData,
  ])
  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, worksheet, "Sheet1")

  // Write XLSX file
  writeFile(workbook, filename)
}

export const downloadCSV = (csvContent, filename) => {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })

  if (navigator.msSaveBlob) {
    // For IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement("a")
    if (link.download !== undefined) {
      // For other browsers
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", filename)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }
}

export const convertToPDF = (data, columns, filename) => {
  if (!data || !columns || data.length === 0) {
    return
  }

  // Initialize jsPDF instance
  const doc = new jsPDF()

  // Set document properties (optional)
  doc.setProperties({
    title: filename,
  })

  // Set font size and styles
  doc.setFontSize(12)

  // Define table header
  const headers = columns.map(column => column.name)

  // Prepare data rows
  const rows = data.map((row, index) =>
    columns.map(column => {
      if (column.name === "#") {
        // Handle row number column
        return index + 1 // Adjust as needed based on startIndex
      } else if (column.name === "Total Amount") {
        // Handle Total Amount column with Naira symbol
        return `₦${row.totalAmount}` // Ensure Naira symbol is correctly handled
      } else {
        // Handle other columns normally
        const value =
          typeof column.selector === "function"
            ? column.selector(row)
            : row[column.selector]
        return value !== undefined ? String(value) : ""
      }
    })
  )

  // Auto table plugin to generate PDF table
  doc.autoTable({
    head: [headers],
    body: rows,
    startY: 20, // Adjust starting position as needed
    margin: { top: 30 },
    styles: { overflow: "linebreak" },
  })

  // Save the PDF file
  doc.save(filename)
}
