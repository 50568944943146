import React, { Fragment, useState, useEffect } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
} from "reactstrap"
import "jspdf-autotable"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import { useMutation } from "react-query"
import { GENERAL } from "../../api/general"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import PaginatedDataTable from "components/PaginatedDataTable"
import { DateRangePicker } from "react-date-range"
import { useParams } from "react-router"
import { useLocation } from "react-router-dom"
import { addDays } from "date-fns"
import { convertToXLSX, convertToPDF } from "helpers/file-download"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const PaymentList = props => {
  const params = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const paramValue = searchParams.get("state")
  const stateId = params.id
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [lgaID, setLgaID] = useState(0)
  const [regCentreID, setRegCentreID] = useState(0)
  const [filter, setFilter] = useState("")
  const [email, setEmail] = useState("")
  const [show, setShow] = useState(false)
  const formatDate = date => date.toLocaleDateString("en-CA")
  const currentDate = new Date()
  const defaultDateFrom = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  )
  const defaultDateTo = currentDate
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ])
  const [filterStartDate, setFilterStartDate] = useState("")
  const [filterEndDate, setFilterEndDate] = useState("")
  function validateEmail(email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return regex.test(email)
  }

  const handleDateChange = item => {
    const selection = item.selection
    setFilterStartDate(formatDate(selection.startDate))
    setFilterEndDate(formatDate(selection.endDate))
    setState([selection])
  }

  const showModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const handleFilter = () => {
    fetchRecords(
      page,
      pageSize,
      filter,
      params.id,
      filterStartDate,
      filterEndDate
    )
    closeModal()
  }

  const handleExcelDownload = () => {
    convertToXLSX(data, columns, "report.xlsx")
    toast("Excel Report Downloaded!")
  }

  const handlePDFDownload = () => {
    convertToPDF(data, columns, "report.pdf")
    toast("PDF Report Downloaded!")
  }

  const fetchRecords = async (
    page,
    pageSize,
    filter,
    stateId,
    dateFrom,
    dateTo
  ) => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    try {
      const response = await generateInvoice.mutateAsync({
        page,
        pageSize,
        searchKey: filter ?? "",
        stateId: params.id,
        dateFrom,
        dateTo,
      })
      Swal.close()
      setData(response.palliative)
      setTotalRecords(response.totalRecords)
    } catch (error) {
      Swal.fire({
        title: "Error fetching records",
        text: error.message,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    fetchRecords(
      page,
      pageSize,
      filter,
      params.id,
      formatDate(defaultDateFrom),
      formatDate(defaultDateTo)
    )
  }, [page, pageSize])

  const generateInvoice = useMutation({
    mutationFn: async ({
      page,
      pageSize,
      searchKey,
      stateId,
      dateFrom,
      dateTo,
    }) => {
      return GENERAL.generateInvoice({
        page,
        pageSize,
        searchKey,
        stateId,
        dateFrom,
        dateTo,
      })
    },
    onError: error => {
      Swal.fire("Error fetching records", error.message, "error")
    },
  })

  const handleSendReportToEmail = () => {
    const payload = {
      stateId: parseInt(stateId),
      lgaId: lgaID,
      regCenter: regCentreID,
      email: email,
      dateFrom: `${filterStartDate}T00:00:00Z`,
      dateTo: `${filterEndDate}T23:59:59Z`,
    }
    if (email == "" || !validateEmail(email)) {
      toast.error("Please enter a valid email!")
    } else if (filterStartDate == "" && filterEndDate == "") {
      toast.error("Please select your start and end dates!")
    } else {
      sendReportToEmail.mutate(payload)
    }
  }

  const sendReportToEmail = useMutation({
    mutationFn: async payload => {
      Swal.fire({
        title: "Sending report...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(Swal.getDenyButton())
        },
      })
      return GENERAL.sendReportToEmail(payload)
    },
    onError: error => {
      Swal.close()
      Swal.fire("Error sending report", error.message, "error")
    },
    onSuccess: res => {
      closeModal()
      const message = res.data.message
      Swal.close()
      Swal.fire("Success", message, "success")
    },
  })

  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = (newPageSize, page) => {
    setPageSize(newPageSize)
    setPage(1)
  }

  const startIndex = (page - 1) * pageSize

  const handleSearchFn = async () => {
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    setPage(1)
    await fetchRecords(
      1,
      pageSize,
      filter.trim(),
      params.id,
      formatDate(defaultDateTo),
      formatDate(defaultDateTo)
    )
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const columns = [
    {
      name: "#",
      sortable: true,
      selector: (row, index) => startIndex + index + 1,
      width: "4rem",
    },
    {
      name: "Application ID",
      selector: row => row.npc_id,
      sortable: true,
    },
    {
      name: "Full Name",
      sortable: true,
      selector: row => row.account_name,
      width: "18rem",
    },
    {
      name: "Phone No.",
      sortable: true,
      selector: row => row.phone,
    },
    {
      name: "State Of Residence",
      sortable: true,
      selector: row => row.state_resid,
    },
    {
      name: "LGA Of Residence",
      sortable: true,
      selector: row => row.lga_resid,
    },
    // {
    //   name: "Registrar",
    //   sortable: true,
    //   //selector: row => row.state_resid,
    // },
    {
      name: "Bank Name",
      sortable: true,
      selector: row => row.bank_name,
      width: "12rem",
    },
    {
      name: "Account No.",
      sortable: true,
      selector: row => row.account_num,
    },
    {
      name: "Total Registrations",
      sortable: true,
      selector: row => row.totalRegistrations,
    },
    {
      name: "Total Amount",
      sortable: true,
      selector: row => `₦${row.totalAmount}`,
    },
  ]

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={show} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            <DateRangePicker
              onChange={handleDateChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
              maxDate={new Date()}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div style={{ flex: "none", margin: "0 10px" }}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "80px" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
              <div style={{ flex: "none", margin: "0 10px" }}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "90px" }}
                  onClick={handleFilter}
                >
                  Generate
                </button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "50px",
              }}
            >
              <div style={{}}>
                <input
                  onChange={e => setEmail(e.target.value)}
                  id="email"
                  type="text"
                  className="form-control"
                  placeholder={`email`}
                  value={email}
                  style={{ width: "300px" }}
                />
              </div>

              <div style={{ flex: "none", margin: "0 10px" }}>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSendReportToEmail}
                >
                  Send to email
                </button>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid className="container-xxl" style={{ minWidth: "97%" }}>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t(`Generate Report`)}
              breadcrumbItem={props.t(`Generate Report (${paramValue} State)`)}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="h4 card-title">All Allowances</div>

              <Fragment>
                <Row className="mb-2 mt-3">
                  <React.Fragment>
                    <Col md={6}>
                      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                        <div
                          className="position-relative"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => setFilter(e.target.value)}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`Search table`}
                              value={filter || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div
                            className="d-inline-block"
                            style={{ marginLeft: 5, marginBottom: 8 }}
                          >
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <Col
                        md={4}
                        className="d-flex justify-content-end align-items-center"
                      ></Col>
                      <Col md={6} className="d-flex justify-content-end">
                        <div className="ms-auto mt-2">
                          <div className="toolbar d-flex flex-wrap gap-2 text-end">
                            <ToastContainer
                              hideProgressBar={true}
                              autoClose={2000}
                              theme="light"
                              toastStyle={{ backgroundColor: "#95d5b2" }}
                            />
                            <Button
                              type="button"
                              color="primary"
                              style={{ fontSize: "12px", padding: "8px 70px" }}
                              onClick={() => showModal()}
                            >
                              Filter
                            </Button>
                            <button
                              type="button"
                              className="btn btn-light btn-sm"
                              onClick={handleExcelDownload}
                            >
                              DOWNLOAD EXCEL
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-light btn-sm"
                              onClick={handlePDFDownload}
                            >
                              DOWNLOAD PDF
                            </button>{" "}
                          </div>
                        </div>
                      </Col>
                    </div>
                  </React.Fragment>
                </Row>

                <PaginatedDataTable
                  columns={columns}
                  data={data}
                  totalRecords={totalRecords}
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                />
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(PaymentList)
