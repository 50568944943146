import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import dayjs from "dayjs"
import "jspdf-autotable"
import * as xlsx from "xlsx"
import saveAs from "file-saver"
import { Button, Card, CardBody, CardTitle } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useQuery } from "react-query"
import { GENERAL } from "api/general"
import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
} from "./LatestTranactionCol"

import TableContainer from "../../components/Common/TableContainer"

const LatestTranaction = props => {
  const navigate = useNavigate()
  const [dashboardStats, setDashboardStats] = useState(null)
  const getDataPlans = useQuery({
    queryKey: ["getDataPlans"],
    queryFn: () => {
      return GENERAL.getDashboardStatistics("goal")
    },
    onSuccess: async res => {
      // const fixedWallets = res.result
      setDashboardStats(res)
    },
  })
  const title = "Latest Tranactions"

  const exportExcel = () => {
    const worksheet = xlsx.utils.json_to_sheet(products)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] }
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    })
    saveAsExcelFile(excelBuffer, title)
  }

  const saveAsExcelFile = (buffer, fileName) => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    let EXCEL_EXTENSION = ".xlsx"
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    })
    saveAs(data, fileName + "export" + new Date().getTime() + EXCEL_EXTENSION)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Application ID",
        accessor: "npc_id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Last Name.",
        accessor: "last_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "State of Residence",
        accessor: "state_resid",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />
        },
      },
      {
        Header: "LGA of Residence",
        accessor: "lga_resid",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        id: "date_created",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DateFormat {...cellProps} />
        },
      },
      {
        Header: "Time Created",
        accessor: "created_at",
        id: "time_created",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <TimeFormat {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => gotoProfile(cellProps)}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const DateFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("DD-MM-YYYY") : ""
  }

  const TimeFormat = cell => {
    return cell.value ? dayjs(cell.value ?? "").format("HH:mm A") : ""
  }

  const gotoProfile = evt => {
    const npc_id = evt?.row?.values?.npc_id
    if (!npc_id) {
      return Swal.fire("Process failed", "User not found", "error")
    }
    navigate(`/profile/${npc_id}`)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Most Recent Registrations</CardTitle>

          {dashboardStats?.latestApplications && (
            <TableContainer
              columns={columns}
              data={dashboardStats?.latestApplications}
              isGlobalFilter={false}
              isAddOptions={false}
              customPageSize={6}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTranaction)
