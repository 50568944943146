import React, { Fragment, useState, useEffect } from "react"
import { Container, Col, Row, Button, Card, CardBody, Label } from "reactstrap"
import { useQuery, useMutation } from "react-query"
import { GENERAL } from "../../../api/general"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import PaginatedDataTable from "components/PaginatedDataTable"
import { convertToXLSX, convertToPDF } from "helpers/file-download"
import { ToastContainer, toast } from "react-toastify"

const ViewApprovedAgentRegistrationsByLga = props => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [filter, setFilter] = useState("")
  const [stateId, setStateId] = useState("")
  const [states, setStates] = useState([])

  const fetchRecords = async (page, pageSize, filter, stateId) => {
    Swal.fire({
      title: "Fetching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })

    try {
      const response = await getApprovedRecordsList.mutateAsync({
        page,
        pageSize,
        searchKey: filter ?? "",
        stateId: stateId ?? "",
      })
      Swal.close()
      setData(response.palliative)
      setTotalRecords(response.totalRecords)
    } catch (error) {
      Swal.fire({
        title: "Error fetching records",
        text: error.message,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    fetchRecords(page, pageSize, filter, stateId)
  }, [page, pageSize])

  const getApprovedRecordsList = useMutation({
    mutationFn: async ({ page, pageSize, searchKey, stateId }) => {
      return GENERAL.getApprovedRecordsList({
        page,
        pageSize,
        searchKey,
        stateId,
      })
    },
    onError: error => {
      Swal.fire("Error fetching records", error.message, "error")
    },
  })

  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = (newPageSize, page) => {
    setPageSize(newPageSize)
    setPage(1) // Reset to first page whenever page size changes
  }

  const startIndex = (page - 1) * pageSize

  const getStates = useQuery({
    queryKey: ["getStates"],
    queryFn: () => {
      return GENERAL.getStates()
    },
    onSuccess: async res => {
      if (res?.palliative) {
        setStates(res.palliative)
      }
    },
  })

  const handleSelectState = evt => {
    setStateId(evt.target.value)
  }

  const handleSearchFn = async () => {
    Swal.fire({
      title: "Searching records...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton())
      },
    })
    setPage(1) // Reset to first page when searching
    await fetchRecords(1, pageSize, filter.trim(), stateId)
  }

  //meta title
  document.title = "Agents Registration - eRecruitement"

  const columns = [
    {
      name: "#",
      sortable: true,
      selector: (row, index) => startIndex + index + 1,
      width: "4rem",
    },
    {
      name: "Application ID",
      selector: row => row.npc_id,
      sortable: true,
    },
    {
      name: "First Name",
      sortable: true,
      selector: row => row.first_name,
    },
    {
      name: "Last Name",
      sortable: true,
      selector: row => row.last_name,
    },
    {
      name: "Phone No.",
      sortable: true,
      selector: row => row.phone,
    },
    {
      name: "State of Residence",
      sortable: true,
      selector: row => row.state_resid,
    },
    {
      name: "LGA of Residence",
      sortable: true,
      selector: row => row.lga_resid,
    },
    {
      name: "Test Score",
      sortable: true,
      selector: row => row.test_score,
    },
    {
      name: "Date Created",
      sortable: true,
      selector: row => new Date(row.created_at).toLocaleDateString(),
    },
    {
      name: "View Details",
      disableFilters: true,
      cell: row => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => gotoProfile(row)}
        >
          View Details
        </Button>
      ),
    },
  ]

  const gotoProfile = row => {
    const npc_id = row?.npc_id
    if (!npc_id) {
      return Swal.fire("Process failed", "User not found", "error")
    }
    navigate(`/profile/${npc_id}`)
  }

  const handleExcelDownload = () => {
    convertToXLSX(data, columns, "report.xlsx")
    toast("Excel Report Downloaded!")
  }

  const handlePDFDownload = () => {
    convertToPDF(data, columns, "report.pdf")
    toast("PDF Report Downloaded!")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Breadcrumbs
              title={props.t("Approved Agents Registration")}
              breadcrumbItem={props.t("Total Approved Agents")}
              hasBackBtn={true}
            />
          </Row>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">All Registration Agents</div>
              <Fragment>
                <Row className="align-items-center">
                  <Col md={12}>
                    <div className="d-flex justify-content-start">
                      <div className="search-box cursor-pointerr">
                        <div>
                          <Label htmlFor="formrow-email-Input">State</Label>
                          <div>
                            <select
                              onChange={handleSelectState}
                              className="form-control"
                              style={{ width: "200px" }}
                            >
                              <option>Select</option>
                              {states?.map(state => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginLeft: "20px", marginTop: "27px" }}
                        className="search-box me-xxl-2"
                      >
                        <div className="position-relative d-flex align-items-center">
                          <label
                            htmlFor="search-bar-0"
                            className="search-label"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={e => setFilter(e.target.value)}
                              id="search-bar-0"
                              type="text"
                              className="form-control"
                              placeholder={`Search table`}
                              value={filter || ""}
                            />
                          </label>
                          <i
                            className="bx bx-search-alt search-icon"
                            style={{ cursor: "pointer" }}
                            onClick={handleSearchFn}
                          ></i>
                          <div className="ms-2">
                            <Button
                              type="button"
                              color="primary"
                              className="btn-md btn-rounded"
                              onClick={handleSearchFn}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-2 mt-3">
                  <React.Fragment>
                    <div className="d-flex justify-content-between align-items-center">
                      <Col
                        md={4}
                        className="d-flex justify-content-end align-items-center"
                      ></Col>
                      <Col md={6} className="d-flex justify-content-end">
                        <div className="ms-auto mt-2">
                          <div className="toolbar d-flex flex-wrap gap-2 text-end">
                            <ToastContainer
                              hideProgressBar={true}
                              autoClose={2000}
                              theme="light"
                              toastStyle={{ backgroundColor: "#95d5b2" }}
                            />
                            <button
                              type="button"
                              className="btn btn-light btn-sm"
                              onClick={handleExcelDownload}
                            >
                              DOWNLOAD EXCEL
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-light btn-sm"
                              onClick={handlePDFDownload}
                            >
                              DOWNLOAD PDF
                            </button>{" "}
                          </div>
                        </div>
                      </Col>
                    </div>
                  </React.Fragment>
                </Row>

                <PaginatedDataTable
                  columns={columns}
                  data={data}
                  totalRecords={totalRecords}
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                />
              </Fragment>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ViewApprovedAgentRegistrationsByLga)
